export const homeArtistTwo = {
    id: 'music',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,        
    GWTitle: "grengingwar.com",     
    GWdescription: "This is a personal project. It is still pre-beta status. Its a browser game.  ",     
    BamTitle: "",     
    Bamdescription: "testing",     
    buttonlabel: 'Next',
    imgStart: true,
    img: require('../../images/gw-logo2.png'),
    dark: false,
    primary: false,
    darkText: true,
};